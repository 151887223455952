import { APIResponse } from '../../helpers'

export interface MediaItem {
  Filename: string
  SizeInBytes: number
  UpdatedAt: string
  ThumbFilename: string
  ThumbSizeInBytes: number
  ThumbUpdatedAt: string
  PublicURL: string
  PathToFile: string
  PathToThumbFile: string
}

export interface MediaItemList {
  Objects: MediaItem[]
}

export type ListMediaResponse = APIResponse<MediaItemList>

export enum MediaAccess {
  Private = 'private',
  Public = 'public'
}

export enum PublicMediaCategories {
  LogoImages = 'logo-images',
  MailingImages = 'mailing-images'
}

export enum PrivateMediaCategories {
  ChecklistImages = 'checklist-images',
  EmployeeImages = 'employee-images',
  MailingAttachments = 'mailing-attachments',
  PdfBackgroundImages = 'pdf-background-images',
  VehicleImages = 'vehicle-images',
  WorkorderImages = 'workorder-images'
}

export const MediaCategories = {
  ...PrivateMediaCategories,
  ...PublicMediaCategories
}

export type MediaCategory = typeof MediaCategories[keyof typeof MediaCategories]
