import { Reducer, combineReducers } from 'redux'
import { createReducer } from '@cbgms/base/utils/redux'
import {
  LOAD_LEDGER_ADMINISTRATIONS_LEDGERS_SUCCESS,
  LOAD_LEDGER_ADMINISTRATIONS_SUCCESS,
  LOAD_LEDGER_REVENUE_GROUPS_SUCCESS,
  LOAD_LEDGER_SUCCESS,
  LOAD_REVENUE_LEDGERS_SUCCESS,
  RESET_LEDGER_OPTIONS
} from 'app/settings/state/Ledgers/types'
import { EmptyUUID } from '@cbgms/base/utils/uuid'
import { ILedger, IRevenueLedger } from '@cbgms/api/modules/ledgers'
import { RevenueGroupKind } from '@cbgms/api/modules/enums'
import { AdministrationListItem, IGetRevenueGroupsItem } from '@cbgms/api'

export type LedgerDetailState = Readonly<Partial<ILedger>>

const defaultDetailState: LedgerDetailState = {
  AccountType: 'all',
  RevenueGroupUUID: EmptyUUID,
  VatType: 'all',
  AdministrationUUID: EmptyUUID,
  IsDefault: true
}

// eslint-disable-next-line carsys-custom/typescript-prefer-interface-extend-over-intersection
type LedgerBehavior<S = LedgerDetailState> = Record<typeof LOAD_LEDGER_SUCCESS, Reducer<S, any>> &
  Record<typeof RESET_LEDGER_OPTIONS, Reducer<S, any>>

const detail = createReducer<LedgerDetailState, LedgerBehavior>(defaultDetailState, {
  [LOAD_LEDGER_SUCCESS]: (_, { payload }) => {
    const detailData = payload.data.Data

    return mapApiLedger(detailData)
  },
  [RESET_LEDGER_OPTIONS]: () => defaultDetailState
})

export interface IAdministrationOptions {
  key: string
  kind?: RevenueGroupKind
  text: string
  value: string
  vatlimiter?: string[]
  code?: string
}

interface IDefaultOptionState {
  administrations: IAdministrationOptions[]
  ledgers: IAdministrationOptions[]
  revenueGroups: IAdministrationOptions[]
}

const defaultOptionsState: IDefaultOptionState = {
  administrations: [],
  ledgers: [],
  revenueGroups: []
}

const options = createReducer(defaultOptionsState, {
  [LOAD_LEDGER_ADMINISTRATIONS_SUCCESS]: (state, { payload }) => {
    return {
      ...(state || defaultOptionsState),
      administrations: payload.data.Data.filter(
        (administration: AdministrationListItem) => administration.Administration.AdministrationExternalID !== ''
      ).map((option: AdministrationListItem) => {
        return {
          key: option.Administration.UUID,
          value: option.Administration.UUID,
          text: `${option.Administration.AdministrationDescription}`,
          code: `${option.Administration.AdministrationExternalID}`
        }
      })
    }
  },
  [LOAD_LEDGER_REVENUE_GROUPS_SUCCESS]: (state, { payload }) => {
    return {
      ...(state || defaultOptionsState),
      revenueGroups: payload.data.Data.map((option: IGetRevenueGroupsItem) => {
        return {
          key: option.RevenueGroup.UUID,
          value: option.RevenueGroup.UUID,
          text: `${option.RevenueGroup.Name}`,
          vatlimiter: option.RevenueGroup.VatLimiter,
          kind: option.RevenueGroup.Kind
        }
      })
    }
  },
  [LOAD_LEDGER_ADMINISTRATIONS_LEDGERS_SUCCESS]: (state, { payload }) => {
    return {
      ...(state || defaultOptionsState),
      ledgers: payload.data.Data.map((option: { Code: string; Description: string }) => {
        return {
          key: option.Code,
          value: option.Code,
          text: `${option.Code} ${option.Description}`
        }
      })
    }
  },
  [RESET_LEDGER_OPTIONS]: () => defaultOptionsState
})

export type LedgerListState = ReadonlyArray<IRevenueLedger>

const defaultListState: LedgerListState = []

type LedgerListBehavior<S = LedgerListState> = Record<typeof LOAD_REVENUE_LEDGERS_SUCCESS, Reducer<S, any>>

const list = createReducer<LedgerListState, LedgerListBehavior>(defaultListState, {
  [LOAD_REVENUE_LEDGERS_SUCCESS]: (_, { payload }) => {
    const listData = payload.data.Data

    // TODO: Refactor this because you're mapping an array and not returning the mapped values.
    listData.map((ledgerItem: IRevenueLedger) => {
      return mapApiLedger(ledgerItem)
    })

    return listData
  }
})

function mapApiLedger(ledgerItem: IRevenueLedger) {
  if (ledgerItem.AccountType === '') {
    ledgerItem.AccountType = 'all'
  }
  if (ledgerItem.VatType === '') {
    ledgerItem.VatType = 'all'
  }
  if (ledgerItem.RevenueGroupName === '') {
    ledgerItem.RevenueGroupName = 'all'
  }

  ledgerItem.IsDefault =
    ledgerItem.AdministrationUUID === EmptyUUID &&
    ledgerItem.RevenueGroupUUID === EmptyUUID &&
    ledgerItem.AccountType === 'all' &&
    ledgerItem.VatType === 'all'

  return ledgerItem
}

export interface ILedgerReducerState {
  list: typeof defaultListState
  detail: typeof defaultDetailState
  options: typeof defaultOptionsState
}

export default combineReducers<ILedgerReducerState>({
  list,
  detail,
  options
})
