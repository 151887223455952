import MUITextField from '@mui/material/TextField'

export type TextFieldProps = React.ComponentProps<typeof MUITextField>

export function TextField(props: TextFieldProps): JSX.Element {
  return (
    <MUITextField
      fullWidth
      autoComplete='off'
      margin='none'
      size='small'
      variant='outlined'
      helperText={props.helperText ? props.helperText : ' '}
      {...props}
    />
  )
}
