import {
  APIResponse,
  CmsSystem,
  GETArrayResponse,
  GETResponse,
  IAccount,
  IPaginatedResponse,
  POSTRequestBody,
  PUTRequestBody
} from '@cbgms/api'
import { CountryCode } from '@carsys/enums/country-code'
import { CatalogStrategyType, OrderSystem, OrderSystemService, TicketSystem, VehicleIdType } from '../enums'

export interface IOrderAccount {
  AccountUUID: string
  ShopID: string
  UUID: string
  UpdatedAt: string
  CreatedAt: string
  WholesalerUUID: string
  Credentials: OrderAccountCredentials
  DoNotShowOnList: boolean
  DisplayRules?: DisplayRules // not present in list
}

export interface OrderAccountCredentials {
  [key: string]: string
}
export interface DisplayRules {
  ShowBandenNuTyresiFrame: boolean
  RuleDisplayProductAvailabilityCallback: boolean
  ShowPlusPartsBanner: boolean
  HideLocalStockLevel: boolean
  HideTotalStockLevel: boolean
  HidePriceStockInCart: boolean
  ShowUniversalPartsAndToolSpecialBanners: boolean
  ShowEquipmentSpecialBanner: boolean
  ShowHBaseVehicleSelector: boolean
  ShowAvailabilityFilter: boolean
  ShowEMDMVehicleSelector: boolean
  ShowSortDropdown: boolean
  OverwriteVehicleProfileOnHBaseVehicle: boolean
  OverwriteVehicleProfileOnTecDocPlusVehicle: boolean
  ShowRMAFunctions: boolean
  UseProductGroupSearchViaAPI: boolean
  HasCMS: boolean
  HideOrderInclVATPrice: boolean
}

export interface IBandenNuURL {
  URL: string
}

export interface IShopWholesaler {
  UUID: string
  OrderSystem: OrderSystem
  Name: string
  LogoURL: string
  IsLinked: boolean
  UserFields: IUserField[]
  Country: string
}

export interface IToggleOrderSystemRequest {
  OrderSystem?: string
  CountryCode?: string
  Service?: string
  Active?: boolean
  Config?: string
  CreatedAt?: string
  UpdatedAt?: string
}

/**
 * fields on IWholesaler that are available within support API routes
 * @interface IWholesalerSupport
 */
export interface IWholesalerSupport extends Omit<IWholesaler, 'UserFields'> {}

/**
 * fields on IWholesaler that are available within GMS API routes
 * @interface IWholesalerGMS
 */
export interface IWholesalerGMS extends Omit<IWholesaler, 'TicketAPIKey' | 'TicketBrandID' | 'TicketAgentEmail' | 'TicketURL'> {}

interface IWholesaler {
  CreatedAt: string
  UpdatedAt: string
  UUID: string
  OrderSystem: OrderSystem
  Country: CountryCode
  Name: string
  LogoURL: string
  UserFields: []
  OrderSystemSettings: { [k: string]: string }
  TermsAndConditionsURL: string
  HaynesProDistributorUUID: string
  IFrameURL: string
  CMSURL?: string
  CMSSystem: CmsSystem
  TicketSystem: TicketSystem
  SupportEmail: string
  SupportPhoneNumber: string
  OrderEmailAcknowledgementWholesalerEnabled: boolean
  OrderEmailAcknowledgementWholesalerEmailAddress: string
  IsActive: boolean
  TicketAPIKey?: string
  TicketBrandID?: string
  TicketAgentEmail?: string
  TicketURL?: string
  HideLocalStock: boolean
  HideTotalStock: boolean
  AvailableForPortal: boolean
}
export interface IActiveWholesalerServices {
  Tyres: boolean
  IFrame: boolean
  Order: boolean
  PriceStock: boolean
  LicencePlate: boolean
  RMA: boolean
  Search: boolean
}

export interface IWholesalerField {
  Description: string
  DisplayName: string
  IsRequired: boolean
  Name: string
}

export interface IUserField extends IWholesalerField {
  Disabled: boolean
}
export interface IWholesalerOrderSystem {
  Name: string
  Country: string
  Services: IActiveWholesalerServices
  WholesalerFields: IWholesalerField[]
  UserFields: IUserField[]
}

export interface IWholesalerService {
  Service: string
  Active: boolean
  Config: string
}

export enum VehicleSource {
  Unconfigured = '',
  CARSYS_HBASE = 'CARSYS_Hbase',
  CARSYS_EMDM = 'CARSYS_EMDM',
  CARSYS_AAA = 'CARSYS_AAA',
  CARSYS_RDW = 'CARSYS_RDW',
  VPOM_EMDM = 'VPOM_EMDM',
  VPOM_TecDocPlus = 'VPOM_TecDocPlus'
}

export enum WholesaleCatalogType {
  HBASE = 'Hbase',
  EMDM = 'EMDM',
  VPOM = 'VPOM',
  CARSYS_PRODUCT_TREE = 'CarsysProductTree',
  WholesalerGenArt = 'WholesalerGenArt',
  TecDoc = 'TecDoc'
}

export interface IWholesalerCatalog extends IPostWholesalerCatalog {
  UUID: string
}

export interface ITreeConfig {
  TreeUUID: string
}
export interface IPostWholesalerCatalog {
  CatalogType: WholesaleCatalogType
  WholesalerUUID: string
  CarsysProductTreeUUID: string
  VehiclePrimaryVehicleSource: VehicleSource
  VehicleSources: VehiclesSourceConfig[]

  VPOMProductTreeID: string
  VPOMDashboardCatalogUUIDS?: ITreeConfig[]
  VPOMUseDevelopmentAPI: boolean
  VPOMHidePDHForNonTyres: boolean
  ExhaustGroupPlaceholder?: string
  SteeringGroupPlaceholder?: string

  RemoteIDOverride?: string

  // HbaseSettings:
  HbaseOverrideSupplierID?: string
  HbaseOverrideAPIKey?: string
  HbaseOverrideMGAppID?: string
  HbaseOverrideMGAppSecret?: string
  HbaseMapSurchargeFromCatalog?: boolean

  // MDM Settings:
  MDMOverrideApplicationID?: string
  MDMOverrideUsername?: string
  MDMOverridePassword?: string
  MDMUseVPOMVehicles?: boolean // This will turn on the vpom vehicle api.
  MDMUseDevelopAPI?: boolean // This will set the vpom client to connect to vpom develop api

  // TecDoc Settings:
  TecDocOverrideProvider?: string
  TecDocOverrideAPIKey?: string
}

export interface IWholesalerOrderSystemService {
  Active: boolean
  Config: string
  CountryCode: CountryCode
  OrderSystem: OrderSystem
  Service: OrderSystemService
  CreatedAt: string
  UpdatedAt: string
}

export interface IOrderAccountData {
  Account: IAccount
  OrderAccount: IOrderAccount
  Wholesaler: IWholesalerGMS
  WholesalerOrderSystemServices: IWholesalerOrderSystemService[]
  Catalog: ICatalog
}

export interface ICatalog {
  UUID: string
  CatalogType: CatalogStrategyType
  CarsysProductTreeUUID: string
  VPOMDashboardCatalogUUIDS: TreeConfig[]
  WholesalerUUID: string
  VehiclePrimaryVehicleSource: VehicleSource
  VPOMUseDevelopmentAPI: boolean
  VPOMHidePDHForNonTyres: boolean
}
export interface VehiclesSourceConfig {
  Country: CountryCode
  Source: VehicleSource
  Target: VehicleIdType
}
export interface TreeConfig {
  TreeUUID: string
}

export interface GETSupportedWholesalerRow {
  SupportedWholesaler: IWholesalerSupport
}

export type POSTWholesalerLogo = APIResponse<{ URL: string; Success: boolean; SizeInBytes: number }>

export type GETOrderAccountResponse = GETResponse<IOrderAccountData>

export type GETOrderAccountsResponse = IPaginatedResponse<IOrderAccountData>

export type GETShopWholesalersResponse = GETArrayResponse<IShopWholesaler>

export type GETSupportWholesalerResponse = GETResponse<IWholesalerSupport>

export type GETSupportWholesalersResponse = GETArrayResponse<IWholesalerSupport>

export type POSTSupportWholesalerBody = POSTRequestBody<IWholesalerSupport, 'Country' | 'LogoURL' | 'Name' | 'OrderSystem'>

export type POSTSupportWholesalerResponse = APIResponse<{ UUID: string }>

export type PUTSupportWholesalerBody = PUTRequestBody<IWholesalerSupport>

export type PUTSupportWholesalerResponse = APIResponse<{ UUID: string }>

export type GETBandenNuURLResponse = GETResponse<IBandenNuURL>
