import { logout, requestNewAccessToken } from 'app/auth/state/actions'
import { credentials } from 'middlewares/errorHandler/utils'
import { IFailedRequest } from '../errorHandler'

class TokenExpirationService {
  private static instance?: TokenExpirationService
  private runningRequest?: Promise<any>

  private containsToken(response: any) {
    return (
      'Data' in response &&
      'AuthenticationResult' in response.Data &&
      'AccessToken' in response.Data.AuthenticationResult &&
      response.Data.AuthenticationResult.AccessToken != null &&
      typeof response.Data.AuthenticationResult.AccessToken === 'string'
    )
  }

  async refreshAccessToken(failedRequest: IFailedRequest) {
    const { request, store } = failedRequest
    const { emailAddress, token } = credentials(store)

    if (this.runningRequest) {
      return this.runningRequest.then(request)
    }

    try {
      this.runningRequest = store.dispatch(requestNewAccessToken({ EmailAddress: emailAddress, RefreshToken: token }))

      const response = await this.runningRequest

      if (!this.containsToken(response)) {
        return store.dispatch(logout())
      }

      return request()
    } catch {
      store.dispatch(logout())
    } finally {
      this.runningRequest = undefined
    }
  }

  static getIntance() {
    if (!TokenExpirationService.instance) {
      TokenExpirationService.instance = new TokenExpirationService()
    }

    return TokenExpirationService.instance
  }
}

function tokenExpired(failedRequest: IFailedRequest) {
  const service = TokenExpirationService.getIntance()

  return service.refreshAccessToken(failedRequest)
}

export default tokenExpired
