import { APIResponse, GETResponse, POSTRequestBody, PUTRequestBody, PaginatedResponse } from '../../helpers'
import { VatType } from '../enums'

export enum LabourRateType {
  Mechanical = 'mechanical',
  Body = 'body',
  Electrical = 'electrical',
  General = 'general'
}

export interface ILabourRate {
  UUID: string
  Code: string
  Description: string
  HourlyRate: number
  RevenueGroupUUID: string
  CreatedAt: string
  UpdatedAt: string
  VatType: VatType
}

export interface ILabourRateDefaults {
  Bodywork: string
  Electrical: string
  Mechanical: string
  TimeFactor: number
}

export interface IGetLabourRateResponse {
  LabourRate: ILabourRate
}

export type GETLabourRateResponse = GETResponse<ILabourRate>

export type POSTLabourRateBody = POSTRequestBody<ILabourRate, 'Code' | 'Description'>
export type POSTLabourRateResponse = APIResponse<{ UUID: string }>

export type PUTLabourRateBody = PUTRequestBody<ILabourRate>
export type PUTLabourRateResponse = APIResponse<{ UUID: string }>

export type GETLabourRatesResponse = PaginatedResponse<IGetLabourRateResponse>

export type GETLabourRateDefaultsResponse = GETResponse<ILabourRateDefaults>
export type PUTLabourRateDefaultsRequest = PUTRequestBody<ILabourRateDefaults>

export type PUTLabourRateDefaultsResponse = GETResponse<ILabourRateDefaults>
