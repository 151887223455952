import type { AcceptedFileTypes } from './types'

export function isMultipleFiles(files: File[]): boolean {
  return files.length > 1
}

export function isAllowedFileTypes(file: File, allowedFileTypes: AcceptedFileTypes[]): boolean {
  return allowedFileTypes.some(fileType => file.type.includes(fileType))
}

export function isWithinMaximumFilesize(file: File, maximumFilesize: number): boolean {
  return bytesToMegabytes(file.size) <= maximumFilesize
}

export function bytesToMegabytes(bytes: number): number {
  const stepSize = 1000
  const stepsTillMegabytes = 2

  return bytes / stepSize ** stepsTillMegabytes
}

export function fileTypesToAcceptString(fileTypes: AcceptedFileTypes[]): string {
  return fileTypes.map(fileType => `.${fileType}`).join(', ')
}
