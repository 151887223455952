import { ChecklistStatus, OrderDeliveryStatus, OrderStatus, WorkorderAppointmentStatus, WorkorderLineStatus } from '../enums'
import { IArrayResponse } from '../../response'
import { IAbsence } from '@cbgms/api/modules/absence-schedule'
import { GETResponse } from '@cbgms/api/helpers'

export interface IDay {
  Date: string
  WeekNumber: number
  DayName: string
  Appointments: IAppointment[]
  Absences: ICalendarAbsence[] | null
  CapacityUsed: number
  CapacityTotal: number
  TotalAppointments: number
  NumberOfMots: number
}

export interface ICalendarAbsence {
  Absence: IAbsence
  EmployeeName: string
  EmployeeProfileColour: string
}

export interface IAppointment extends IWorkorderAppointment {
  UUID: string
  VehicleUUID: string
  WorkorderUUID: string
  EmployeeProfileColour: string
  EmployeeName: string
  VehicleRegistration: string
  ContainsMOT: boolean
  ContainsTyrechange: boolean
  Activities: IActivity[]
  LineStatus: WorkorderLineStatus | ''
  OrderStatus: OrderStatus
  DeliveryStatus: OrderDeliveryStatus
  ChecklistStatus: ChecklistStatus | ''
  AppointmentColors: string[]
  AccountName: string
  AccountType: string
  EstimatedDuration: number
  VehicleType: string
  VehicleManufacturer: string
  VehicleModel: string
  VehicleVIN?: string
  ClockingActions: IClockingAction[]
  ClockedDuration: number
  IsClockedIn: boolean
  InternalRemark: string
  IconInfo: string
  ContainsCourtesyVehicle: boolean
  CourtesyVehicleRegistration: string
  AppointmentStatus?: WorkorderAppointmentStatus
  WorkorderTotalVatIncluded: number
}

export interface IClockingAction {
  UUID: string
  EmployeeName: string
  ClockedInAt: string
  ClockedOutAt: string
  EmployeeProfileColour: string
}

export interface IWorkorderAppointment {
  UUID?: string
  WorkorderUUID: string
  EmployeeUUID?: string
  EmployeeName?: string
  WorkstationUUID?: string
  WorkstationName?: string
  Date: string
  StartingTime?: string
  EndingTime?: string
  IsProposal?: boolean
  CustomerWaitsInShop?: boolean
  Status?: WorkorderAppointmentStatus
}

export interface IWorkorderAppointmentCreateUpdate {
  WorkorderUUID: string
  Date: string
  StartingTime: string
  EndingTime?: string
  CustomerWaitsInShop?: boolean
  Status?: WorkorderAppointmentStatus
  EmployeeUUID?: string
  WorkstationUUID?: string
  IsProposal?: boolean
}

export interface IActivity {
  IncludeTyreChange: boolean
  IncludesMOTCheck: boolean
  Done: boolean
  Colour: string
  Name: string
  DefaultDuration: number
}

export interface ILoadCalendarRequest {
  StartDate: string
  DaysToShow?: number
  WorkDays?: number
  IsShift?: boolean
  ExcludeEmptyTime?: boolean
}

export interface IAvailabilityDate {
  Date: string
  Availability: AvailabilityStatus
}

export type GetAvailabilityDateResponse = IArrayResponse<IAvailabilityDate>
export type GETWorkorderAppointment = GETResponse<IWorkorderAppointment>

export enum AvailabilityStatus {
  Available = 'high',
  Busy = 'medium',
  Full = 'low',
  Closed = 'closed'
}

export interface AppointmentEmailBody {
  EmailAddress: string
  FirstName?: string
  Name?: string
  NamePrefix?: string
  Locale?: string
}

export enum AppointmentEmailType {
  Scheduled = 'scheduled',
  Updated = 'updated'
}
