import { IFilter, IFilterValue, IProduct, IProductWithState, PartAttributeFilterType } from '@cbgms/api'
import { AnyAction } from 'redux'
import {
  APPLY_FILTER_SWITCH,
  CLEAR_FILTERS,
  IActiveFilterDictionary,
  IBaselineOptions,
  IFilterDictionary,
  IFilterDictionaryEntry,
  IFilterWithoutNestedValues,
  INamedProductFilterState,
  IProductFiltersState,
  IUniqueProductIDSDictionary,
  IValueDictionary,
  SET_FILTER_BASELINE
} from '@cbgms/components/src/order-list/context/filters/ProductFiltersTypes'
import intersection from 'lodash/intersection'
import { isEmptyDateTime } from '@cbgms/base/utils/datetime'

const initializeDefaultState = (): IProductFiltersState => ({})

export const defaultIProductFilterState: INamedProductFilterState = {
  hadStock: false,
  filterBaseLine: {},
  activeFilters: {},
  uniqueProductIDS: {}
}

const ProductFiltersReducer = (state: IProductFiltersState = initializeDefaultState(), action: AnyAction) => {
  switch (action.type) {
    case SET_FILTER_BASELINE:
      return buildFilterBaseline(state, action.payload)
    case CLEAR_FILTERS:
      return clearFilters(state, action.payload)
    case APPLY_FILTER_SWITCH:
      return applyFilter(state, action.payload)
    default:
      return state
  }
}

function buildFilterBaseline(
  state: IProductFiltersState,
  payload: {
    productListName: string
    filters: IFilter[]
    products: IProductWithState[]
    options: IBaselineOptions
  }
): IProductFiltersState {
  const hadStock = !payload.products.find(p => !p.hasLoadedAvailability)
  const allProductIDS = Array.from(new Set([...payload.products.map(p => getUniqueId(p))])) // sometimes we do get duplicate ids.
  if (!didProductIDsChange(payload.productListName, allProductIDS, state)) {
    // check whether the product-ids changed. If they did not change. assume nothing has changed and skip new baseline
    return replaceAvailabilityFilter(state, payload, hadStock)
  }

  const _filters = [...payload.filters]
  const uniqueProductIDS = showAll(allProductIDS)

  // determine if we should include availability filters
  if (payload.options.includeAvailabilityFilter) {
    const filter = buildAvailabilityFilter(payload.products, payload.options)
    _filters.unshift(filter)
  }

  // construct the actual baseline.
  const filterBaseLine = _filters.reduce<IFilterDictionary>((filterResult, filter) => {
    filterResult[filter.Key] = reduceFilterToBaseline(filter)
    return filterResult
  }, {})

  return {
    [payload.productListName]: {
      hadStock,
      filterBaseLine,
      activeFilters: {},
      uniqueProductIDS
    }
  }
}

function buildAvailabilityFilter(products: IProductWithState[], options: IBaselineOptions) {
  return {
    FilterType: PartAttributeFilterType.Checkbox,
    DisplayName: options.translations.productFilterAvailable,
    Key: 'Available',
    SortOrder: -1,
    Values: [
      {
        UniqueIDs: products
          .filter(product => product.LocalStock > 0 || product.TotalStock > 0 || !isEmptyDateTime(product.DeliveryTime))
          .map(product => getUniqueId(product)),
        SortOrder: 0,
        Value: options.translations.globalYes
      }
    ].filter(v => v.UniqueIDs.length)
  }
}

function didProductIDsChange(productListName: string, newIDS: string[], state: IProductFiltersState) {
  const exsistingIDs = Object.entries(state[productListName]?.uniqueProductIDS || {}).map(([_, id]) => id?.uniqueID || '')
  return !areStringArraysEqual(newIDS, exsistingIDs)
}

const areStringArraysEqual = (a: string[], b: string[]) => {
  if (a === b) return true
  if (a.length !== b.length) return false

  const sortedA = [...a].sort((a1, b1) => a1.localeCompare(b1))
  const sortedB = [...b].sort((a1, b1) => a1.localeCompare(b1))
  for (let i = 0; i < sortedA.length; ++i) {
    if (sortedA[i] !== sortedB[i]) return false
  }
  return true
}

function reduceShowAll(result: IUniqueProductIDSDictionary, uniqueID: string): IUniqueProductIDSDictionary {
  result[uniqueID] = { uniqueID, isMatching: true }
  return result
}

function reduceHideAll(result: IUniqueProductIDSDictionary, uniqueID: string): IUniqueProductIDSDictionary {
  result[uniqueID] = { uniqueID, isMatching: false }
  return result
}

function showAll(allProductIds: string[]): IUniqueProductIDSDictionary {
  return allProductIds.reduce<IUniqueProductIDSDictionary>(reduceShowAll, {})
}

function clearFilters(state: IProductFiltersState, payload: { productListName: string }): IProductFiltersState {
  const uniqueProductIDS = Object.keys(state[payload.productListName]?.uniqueProductIDS || {}).reduce(reduceShowAll, {})
  return {
    [payload.productListName]: {
      ...defaultIProductFilterState,
      ...state[payload.productListName],
      activeFilters: {},
      uniqueProductIDS
    }
  }
}

function applyFilter(
  state: IProductFiltersState,
  payload: { productListName: string; filter: IFilterWithoutNestedValues; value: IFilterValue; turnOn: boolean }
): IProductFiltersState {
  // prepare new active filters
  const newActiveFilters: IActiveFilterDictionary = {
    ...(state[payload.productListName]?.activeFilters || {})
  }
  if (!newActiveFilters[payload.filter.Key]) {
    newActiveFilters[payload.filter.Key] = {}
  }
  newActiveFilters[payload.filter.Key]![payload.value.Value] = {
    filterKey: payload.filter.Key,
    filterValueKey: payload.value.Value,
    isOn: payload.turnOn,
    uniqueIDS: payload.value.UniqueIDs
  }

  // check if there's any active filter left.
  // if there's none we can clear the filters instead
  const hasZeroActiveFilters = Object.entries(newActiveFilters).every(([_, filter]) => {
    if (!filter) {
      return true
    }
    return Object.entries(filter).every(([_, filterValue]) => {
      return !filterValue?.isOn
    })
  })
  if (hasZeroActiveFilters) {
    return clearFilters(state, { productListName: payload.productListName })
  }

  // apply the effect of this filter:
  // find all uniqueIDS that should be shown
  const allUniqueIDsPerFilter = Object.entries(newActiveFilters)
    .map(([_, filter]) => {
      if (!filter) {
        return []
      }
      // collect all uniqueID's for this filter,
      // We apply an 'OR'-match for values inside the same filter
      // we apply an 'AND'-match on when combining values for different filters
      const allUniqueIDsForFilter = Object.entries(filter).flatMap(([_, filterValue]) => {
        if (!filterValue?.isOn) {
          return []
        }
        return filterValue.uniqueIDS
      })
      return [...new Set(allUniqueIDsForFilter)]
    })
    .filter(arr => arr.length >= 1 /* remove empty arrays, otherwise we would filter everything */)

  // apply an AND-match between different filters
  const uniqueIDSFromActiveFilters = intersection(...allUniqueIDsPerFilter) as string[]

  // build new state,
  // hide all initially and then flag to show them
  const newUniqueProductIDS = Object.keys({ ...state[payload.productListName]?.uniqueProductIDS }).reduce(reduceHideAll, {})
  for (let i = 0; i < uniqueIDSFromActiveFilters.length; i++) {
    if (!newUniqueProductIDS[uniqueIDSFromActiveFilters[i]]) {
      newUniqueProductIDS[uniqueIDSFromActiveFilters[i]] = { isMatching: true, uniqueID: uniqueIDSFromActiveFilters[i] }
    }
    newUniqueProductIDS[uniqueIDSFromActiveFilters[i]]!.isMatching = true
  }

  // set new state
  return {
    [payload.productListName]: {
      ...defaultIProductFilterState,
      ...state[payload.productListName],
      activeFilters: newActiveFilters,
      uniqueProductIDS: newUniqueProductIDS
    }
  }
}

export const getUniqueId = (input: IProduct) => {
  return `${input.IDType}_${input.ProductID}_${input.BaseID}`
}

function replaceAvailabilityFilter(
  state: IProductFiltersState,
  payload: { productListName: string; filters: IFilter[]; products: IProductWithState[]; options: IBaselineOptions },
  hadStock: boolean
): IProductFiltersState {
  if (!hadStock || state[payload.productListName]?.hadStock) {
    // no new stock OR already had stock. No need to rebuild with availability.
    return state
  }
  // product list reference has changed, but all IDS are still the same
  // likely the availablity has changed.
  // rebuild the availability filter.
  const filter = buildAvailabilityFilter(payload.products, payload.options)
  const newfilterBaseline = { ...state[payload.productListName]?.filterBaseLine, [filter.Key]: reduceFilterToBaseline(filter) }
  return {
    [payload.productListName]: {
      ...defaultIProductFilterState,
      ...state[payload.productListName],
      filterBaseLine: newfilterBaseline,
      hadStock
    }
  }
}

function reduceFilterToBaseline(filter: IFilter): IFilterDictionaryEntry {
  const values = filter.Values.reduce<IValueDictionary>((valuesResult, value) => {
    const v = { ...value, UniqueIDs: [...new Set(value.UniqueIDs)] }
    valuesResult[value.Value] = { filterKey: filter.Key, filterValueKey: v.Value, filterValue: v }
    return valuesResult
  }, {})

  return {
    filter: { DisplayName: filter.DisplayName, FilterType: filter.FilterType, Key: filter.Key, SortOrder: filter.SortOrder },
    values
  }
}

export default ProductFiltersReducer
