export { ProposalSource } from './proposal-source'
export { IntakeType } from './intake-type'
export { WorkorderCreationBehavior } from './workorder-creation-behavior'
export { ShopStatus } from './shop-status'
export { TicketType } from './support-ticket-type'
export { TicketContactPreference } from './support-ticket-contact-preference'

export interface EnumValues {
  readonly [enumName: string]: string[]
}

export enum WorkorderLineStatus {
  Quotation = 'quote',
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Locked = 'locked'
}

export enum WorkorderStatus {
  Quotation = 'quote',
  Workorder = 'workorder'
}

export enum WorkorderType {
  WorkShop = 'workshop',
  RentalContract = 'rental_contract'
}

export enum WorkorderLineType {
  Custom = 'text', // the previous text line is now a custom line, but stored as 'text' in the database
  Text = 'remark', // the new text line is stored as 'remark' in the database
  ExternalPart = 'external_part',
  Surcharge = 'surcharge',
  WarehousePart = 'warehouse_part',
  LabourRate = 'rate',
  Activity = 'activity',
  Package = 'package',
  Sundries = 'sundries',
  CourtesyVehicle = 'courtesy_vehicle',
  RentalVehicle = 'rental_vehicle',
  None = ''
}

export enum WorkorderAppointmentStatus {
  DueIn = 'due_in',
  ReadyForWorkshop = 'ready_for_workshop',
  InProgress = 'in_progress',
  Paused = 'paused',
  ReadyForInvoice = 'ready_for_invoice'
}

export enum QuotationStatus {
  Valid = 'valid',
  Expired = 'expired',
  Converted = 'converted'
}

export enum VehicleSalesType {
  None = '',
  BasePrice = 'base_price',
  BasePriceNew = 'base_price_new',
  Bpm = 'bpm',
  BpmNew = 'bpm_new',
  Misc = 'misc',
  MiscNew = 'misc_new'
}

export enum VehicleAssignedAs {
  None = '',
  VehicleSale = 'vehicle_sales',
  Rental = 'rental',
  Courtesy = 'courtesy'
}

export enum MDMSource {
  Unknown = '',
  AAA = 'AAA',
  ADS = 'ADS Europe',
  CARTEL = 'Cartell',
  DVLA = 'DVLA',
  DutchDVLA = 'DutchDVLA',
  Edidomus = 'Edidomus',
  LKQ = 'LKQ',
  Traxio = 'traxio',
  Sator = 'Sator',
  SMMT = 'SMMT',
  VDS = 'VDS',
  HaynesPro = 'haynespro'
}

export enum RevenueGroupKind {
  Workshop = 'workshop',
  CarSales = 'vehicle_sales'
}

export enum OrderSystem {
  NA = '',
  S1 = 'S1',
  MijnGrossier = 'MijnGrossier',
  RAX = 'RAX',
  K8 = 'K8',
  VROOAM = 'VROOAM',
  Brightmotive = 'Brightmotive',
  GrossierNu = 'GrossierNu',
  Autotaal = 'Autotaal',
  Deltec = 'Deltec',
  GSI = 'GSI',
  Aldoc = 'Aldoc'
}

export enum CatalogStrategyType {
  Hbase = 'Hbase',
  EMDM = 'EMDM',
  VPOM = 'VPOM',
  CarsysProductTree = 'CarsysProductTree',
  WholesalerGenArt = 'WholesalerGenArt',
  TecDoc = 'TecDoc'
}

export enum OrderSystemService {
  Order = 'Order',
  PriceStock = 'PriceStock',
  LicencePlate = 'LicencePlate',
  RMA = 'RMA',
  Search = 'Search',
  IFrame = 'IFrame',
  Tyres = 'Tyres'
}

export enum ForeignWorkorderLineType {
  HaynesProRepairTime = 'haynespro_repairtime',
  HaynesproMaintenancePeriod = 'haynespro_maintenance_period',
  HaynesProMaintenanceTask = 'haynespro_maintenance_task',
  TecRMIRepairTime = 'tecrmi_repairtime',
  TecRMIMaintenancePeriod = 'tecrmi_maintenance_period',
  TecRMIMaintenanceTask = 'tecrmi_maintenance_task',
  LKQJobRepairTime = 'lkqjob_repairtime'
}

export enum OrderStatus {
  NA = '',
  Ordered = 'ordered',
  PartiallyOrdered = 'partially_ordered',
  NotOrdered = 'not_ordered'
}

export enum OrderDeliveryStatus {
  NA = '',
  Delivered = 'delivered',
  PartiallyDelivered = 'partially_delivered',
  NotDelivered = 'not_delivered'
}

export enum OrderType {
  NA = '',
  Online = 'online', // ordered online through an order system
  Offline = 'offline' // ordered offline by the mechanic directly with the supplier
}

export enum ReplacementVehicleBookingStatus {
  Concept = 'concept',
  Booked = 'booked',
  ContractMade = 'contract_made',
  CheckedOut = 'checked_out',
  Complete = 'complete'
}

export enum VatType {
  High = 'high',
  LowOne = 'low_one',
  LowTwo = 'low_two',
  LowThree = 'low_three',
  Zero = 'zero',
  IntraCommunity = 'intra_community',
  OutsideEu = 'outside_eu',
  Margin = 'margin',
  None = 'none'
}

export enum WorkorderLineGroup {
  Other = 'other',
  Part = 'part',
  Labour = 'labour'
}

export enum FuelType {
  Petrol = 'petrol',
  Diesel = 'diesel',
  LPG = 'lpg',
  Electricity = 'electricity',
  LNG = 'lng',
  CNG = 'cng',
  Hydrogen = 'hydrogen',
  Alcohol = 'alcohol'
}

export enum InvoiceType {
  WorkorderCustomer = 'workorder_customer',
  WorkorderInternal = 'workorder_internal'
}

export enum InvoiceTypeWithVehicleSales {
  WorkorderCustomer = 'workorder_customer',
  WorkorderInternal = 'workorder_internal',
  VehiclePurchase = 'vehicle_purchase',
  VehicleSale = 'vehicle_sale'
}

export enum AccountType {
  Private = 'private',
  Business = 'business',
  Dealer = 'dealer'
}

export enum AccountNumberType {
  Creditor = 'creditor',
  Debtor = 'debtor'
}

// enum is called position on the backend
// it corresponds to the current job position of an employee
export enum FunctionType {
  Mechanic = 'mechanic',
  MotInspector = 'mot_inspector',
  ShopManager = 'shop_manager',
  WorkshopManager = 'workshop_manager',
  WarehouseManager = 'warehouse_manager',
  Frontdesk = 'frontdesk',
  CarSales = 'car_sales',
  Director = 'director'
}

export enum BodyType {
  Ambulance = 'ambulance',
  ArmouredVehicle = 'armoured_vehicle',
  CentreAxleTrailer = 'centre_axle_trailer',
  ChassisCab = 'chassis_cab',
  Convertible = 'convertible',
  Coupe = 'coupé',
  DrawbarTrailer = 'drawbar_trailer',
  Hatchback = 'hatchback',
  Hearse = 'hearse',
  Lorry = 'lorry',
  MobileCrane = 'mobile_crane',
  MotorCaravan = 'motor_caravan',
  Mpv = 'mpv',
  PickupTruck = 'pickup_truck',
  Saloon = 'saloon',
  SemiTrailer = 'semi_trailer',
  SemiTrailerTowingVehicle = 'semi_trailer_towing_vehicle',
  SpecialPurposeVehicle = 'special_purpose_vehicle',
  Stationwagon = 'stationwagon',
  TrailerCaravan = 'trailer_caravan',
  TrailerTowingVehicle = 'trailer_towing_vehicle',
  TruckStationWagon = 'truck_station_wagon',
  Unknown = 'unknown',
  Van = 'van',
  WheelchairAccessibleVehicle = 'wheelchair_accessible_vehicle'
}

export enum VehicleType {
  Bicycle = 'bicycle',
  Bus = 'bus',
  CentreAxleTrailer = 'centre_axle_trailer',
  CommercialVehicle = 'commercial_vehicle',
  CommercialVehicleWithTowHitch = 'commercial_vehicle_with_tow_hitch',
  DrawbarTrailer = 'drawbar_trailer',
  Forklift = 'forklift',
  LightCommercialVehicle = 'light_commercial_vehicle',
  Moped = 'moped',
  Motorcycle = 'motorcycle',
  MotorcycleWithSidecar = 'motorcycle_with_sidecar',
  PassengerCar = 'passenger_car',
  SemiTrailer = 'semi_trailer',
  ThreeWheeler = 'three_wheeler',
  Trailer = 'trailer',
  Truck = 'truck',
  Unknown = 'unknown',
  Van = 'van'
}

export enum OdometerLogReason {
  Updated = 'updated',
  Invoice = 'invoice',
  MOTCheck = 'mot_check',
  AddedToInventory = 'added_to_inventory',
  ChangedOwner = 'changed_owner',
  LPG = 'lpg',
  Tachograph = 'tachograph',
  Exported = 'exported',
  Deconstructed = 'deconstructed'
}

export enum ReturnReason {
  CustomerNotShowingUp = 'customer_not_showing_up',
  Damaged = 'damaged',
  DoubleDelivery = 'double_delivery',
  Incomplete = 'incomplete',
  MultipleAlternatives = 'multiple_alternatives',
  NotNeeded = 'not_needed',
  WrongDelivery = 'wrong_delivery',
  WrongInDocumentation = 'wrong_in_documentation',
  WrongPackaging = 'wrong_packaging'
}

export enum TransmissionType {
  Unknown = 'unknown',
  Manual = 'manual',
  Automatic = 'automatic',
  SemiAutomatic = 'semi_automatic',
  ContinuouslyVariable = 'continuously_variable',
  InfinitelyVariable = 'infinitely_variable',
  ElectricVariable = 'electric_variable'
}

export enum SupportMediaNamespace {
  ThemeImages = 'themes',
  WholesalerImages = 'wholesaler'
}

export enum DeliveryMoment {
  Fast = 'fast',
  Medium = 'medium',
  Slow = 'slow',
  Unknown = 'unknown',
  SalesAgent = 'sales_agent'
}

export enum ProductType {
  Regular = 'regular',
  Pseudo = 'pseudo'
}

export enum DeliveryOption {
  DefaultDelivery = 'default_delivery',
  Delivery = 'delivery',
  Pickup = 'pickup',
  Asap = 'asap',
  DeliverJustInTime = 'deliver_just_in_time',
  PreferredDelivery = 'preferred_delivery'
}

export enum KnockTypes {
  String = 'string',
  StringArray = 'DbStringSlice',
  Date = 'Date',
  DateTime = 'DateTime',
  Number = 'int',
  Decimal = 'Decimal',
  Registration = 'Registration',
  Money = 'Money'
}

export enum KnockOrderByOptions {
  Ascending = 'ASC',
  Descending = 'DESC',
  None = ''
}

export enum TyreType {
  None = 'none',
  AllSeason = 'allseason',
  Summer = 'summer',
  Winter = 'winter',
  Special = 'special'
}

export enum TyreSeasonChangeType {
  Unknown = 'unknown',
  Summer = 'summer',
  Winter = 'winter'
}

export enum SeasonIndicatorType {
  Summer = 'summer',
  Winter = 'winter'
}

export enum TyreLabel {
  Unknown = '',
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G'
}

export enum AccountancyExportStatus {
  Queued = 'queued',
  Exported = 'exported',
  Error = 'error',
  ManuallyAccepted = 'manually-accepted'
}

export enum AccountancyManualExportStatus {
  Queued = 'queued',
  Accepted = 'accepted',
  Check = 'check',
  Decline = 'declined'
}

// Commented out options are not available through the frontend right now
// they are however available in the backend enum.
export enum AccountancySoftware {
  ExactOnlineNL = 'exactonline-nl',
  ExactOnlineBE = 'exactonline-be',
  // ExactOnlineFR = 'exactonline-fr',
  ExactOnlineDE = 'exactonline-de',
  SageBusinessCloudUK = 'sagebusinesscloud-uk',
  TwinfieldNL = 'twinfield-nl',
  Sage50OfflineUK = 'sage50-uk',
  DatevOfflineDE = 'datev-de',
  SageOneOfflineUK = 'sageone-uk',
  QuickBooksUK = 'quickbooksonline-uk',
  SnelstartNL = 'snelstart-nl',
  Unit4NL = 'unit4-nl',
  VismaNL = 'visma-nl',
  XeroUK = 'xero-uk',
  BoekhoudgemakNL = 'boekhoudgemak-nl'
}

// Commented out options are not available through the frontend right now
// they are however available in the backend enum.
export enum AccountancyOnlineSoftware {
  ExactOnlineNL = 'exactonline-nl',
  ExactOnlineBE = 'exactonline-be',
  ExactOnlineFR = 'exactonline-fr',
  ExactOnlineDE = 'exactonline-de',
  SageBusinessCloudUK = 'sagebusinesscloud-uk',
  TwinfieldNL = 'twinfield-nl',
  QuickBooksUK = 'quickbooksonline-uk',
  SnelstartNL = 'snelstart-nl',
  Unit4NL = 'unit4-nl',
  VismaNL = 'visma-nl',
  XeroUK = 'xero-uk',
  BoekhoudgemakNL = 'boekhoudgemak-nl'
}

export enum AccountancyOfflineSoftware {
  Sage50OfflineUK = 'sage50-uk',
  DatevOfflineDE = 'datev-de',
  Fmuta6OfflineNL = 'fmuta6-nl' // standard developed by exact, supported by many accountancy software for offline invoice import
  // SageOneOfflineUK = 'sageone-uk' // TODO: GMS-5605 -> re-enable when Sage One is supported
}

export enum EmailOptions {
  Other = 'other',
  Work = 'work'
}

export enum EmailStatus {
  Queued = 'queued',
  Sent = 'sent',
  Bounced = 'bounced',
  Complaint = 'complaint',
  Delivered = 'delivered',
  Unknown = 'unknown'
}

export enum UserStatus {
  Unconfirmed = 'unconfirmed',
  Confirmed = 'confirmed',
  Archived = 'archived',
  Compromised = 'compromised',
  Unknown = 'unknown',
  ResetRequired = 'reset_required',
  ForceChangePassword = 'force_change_password'
}

export enum SmsStatus {
  Delivered = 'delivered'
}

export enum ResultObjectType {
  Invoice = 'invoice',
  GenericVehicle = 'generic_vehicle',
  DatabaseVehicle = 'database_vehicle',
  Account = 'account',
  VehicleWithAccount = 'vehicle_with_account',
  Error = 'error',
  Parts = 'external_product',
  RecentlyOpened = 'recently_opened',
  WorkorderQuotation = 'workorder_quotation',
  VehicleSalesQuotation = 'vehicle_sales_quotation',
  Order = 'order', // these are supplier orders
  WorkOrder = 'workorder',
  WorkOrderArchive = 'workorder_archive',
  TyreSize = 'tyre_size'
}

export enum UserType {
  Account = 'account',
  Employee = 'employee',
  Support = 'support'
}

export enum EmployeeStatus {
  InService = 'in_service',
  OutOfService = 'out_of_service'
}

export enum EmployeeAvailability {
  OnDuty = 'on_duty',
  OnLeave = 'on_leave'
}

export enum ExportTheseInvoices {
  All = 'all',
  NotSent = 'not_sent',
  Errors = 'errors'
}

export enum ExportInvoiceType {
  WorkorderInvoices = 'workorder-invoices',
  InventoryVehicleInvoices = 'inventory-vehicle-invoices',
  JournalEntry = 'journal-entry'
}

export enum ExportTheseTypes {
  All = 'all',
  WorkorderInvoices = 'workorder-invoices',
  InventoryVehicleInvoices = 'inventory-vehicle-invoices',
  JournalEntry = 'journal-entry'
}

export enum Roles {
  None = 'none',
  Admin = 'admin',
  SupportDesk = 'support_desk',
  LKQConceptManager = 'lkq_concept',
  LKQProductManager = 'lkq_product',
  Telesales = 'telesales',
  LessorAndConcept = 'lessor_and_concept'
}

export enum VehicleProvider {
  Rdw = 'rdw',
  TecDoc = 'tecdoc',
  TecDocPlus = 'tecdoc_plus',
  TripleAAA = 'triple_aaa',
  Hbase = 'hbase',
  Haynes = 'haynespro',
  Mdm = 'mdm',
  CarDna = 'cardna'
}

export enum GmsSystemType {
  Web = 'web_app',
  Mobile = 'mobile_app'
}

export enum RDWProviderSystem {
  A2SP = 'A2SP',
  RDC = 'RDC',
  VWE = 'VWE'
}

export enum RDWCapability {
  APK = 'APK',
  OKR = 'OKR'
}

export enum RDWVehicleType {
  Trailer = 'trailer',
  CommercialVehicle = 'commercial_vehicle',
  Moped = 'moped',
  Tricycle = 'tricycle',
  AgricultureTrailer = 'agriculture_trailer',
  AgricultureTractor = 'agriculture_tractor',
  Motorcycle = 'motorcycle',
  PrivateVehicle = 'private_vehicle',
  MotorizedVehicleWithSpeedLimiter = 'motorized_vehicle_with_speed_limiter',
  Unknown = 'unknown'
}

export enum RDWExtendedUtilityType {
  AS = 'alcohol_lock',
  LP = 'lpg_system',
  TA = 'tachometer',
  SB = 'speed_limiter',
  RL = 'particle_filter_light',
  RZ = 'particle_filter_heavy',
  BT = 'taxi_meter',
  Unknown = 'unknown'
}

export enum RDWExtendedUtilityClass {
  G2 = 'second_generation',
  G3 = 'third_generation',
  R1 = 'retrofit',
  Unknown = 'unknown'
}

export enum RDWInspectionResult {
  Passed = 'passed',
  Rejected = 'rejected',
  Unknown = 'unknown'
}

export enum RDWNotificationType {
  Info = 'info',
  Warning = 'warning',
  UserError = 'user_error',
  SoftwareError = 'software_error',
  Unknown = 'unknown'
}

export enum RDWInspectionError {
  HasOpenRandomCheck = 'has_open_random_check',
  AlreadyFinished = 'already_finished'
}

export enum APKStatus {
  InProgress = 'in_progress',
  RandomCheckWait = 'random_check_wait',
  RandomCheckExpired = 'random_check_expired',
  Finished = 'finished'
}

export enum RDWDefectType {
  CauseOfRejection = 'cause_of_rejection',
  TimelyRepairmentAdvised = 'timely_repairment_advised',
  Note = 'note',
  RepairmentAdvised = 'repairment_advised',
  RepairmentBeforePassing = 'repairment_before_passing',
  Unknown = 'unknown'
}

export enum SurchargeType {
  Surcharge = 'Surcharge',
  Lead = 'Lead',
  Deposit = 'Deposit',
  DisposalFee = 'DisposalFee',
  EnvironmentalTax = 'EnvironmentalTax'
}

export enum ROBWorkorderLineModificationStatus {
  None = 'none',
  Modified = 'modified',
  Pending = 'pending',
  Cancelled = 'cancelled',
  Archived = 'archived',
  Deleted = 'deleted',
  Unknown = 'unknown'
}

export enum ROBErrorType {
  Message = 'message',
  Warning = 'warning',
  UserAction = 'user',
  LinkUUID = 'uuid',
  Fatal = 'fatal',
  None = 'none',
  Unknown = 'unknown'
}

// TODO: N-0200: This enum is redundant once the workorderlines get the correct status from API
export enum ROBWorkorderStatus {
  ActionRequired = 'LEV',
  Waiting = 'LM',
  Rejected = 'NAC',
  Approved = 'ACC'
}

export enum ROBWorkorderLineStatus {
  Pending = 'incomplete', // orange
  Approved = 'ready', // green
  Rejected = 'empty' // red
}

export enum ROBWorkorderLineROBStatus {
  Unknown = 'unknown', // grey
  Pending = 'pending', // orange
  Approved = 'approved', // green
  Rejected = 'rejected' // red
}

export enum ROBAccountDeleteReason {
  TerminatedByROB = 'terminated_by_rob',
  NewAccount = 'created_new',
  CreatedDuplicate = 'duplicate'
}

export enum RDWInspectorAcknowledgement {
  LightVehicles = 'light',
  HeavyVehicles = 'heavy',
  Agriculture = 'agriculture',
  Unknown = 'unknown'
}

export enum RDWObfcmReason {
  NotApplicable = 'not_applicable',
  TechnicalReason = 'technical_reason',
  DriverPermissionDenied = 'owner_denied_permission'
}

export enum AbsenceScheduleType {
  SickLeave = 'sick_leave',
  PaidLeave = 'paid_leave',
  UnpaidLeave = 'unpaid_leave',
  CompulsoryLeave = 'compulsory_leave',
  SpecialLeave = 'special_leave',
  BankHoliday = 'bank_holiday'
}

export enum RDWInspectionAuthorityType {
  DistrictOffice = 'district_office',
  OutdoorArea = 'outdoor_area',
  MobileUnit = 'mobile_unit',
  ExternalInstance = 'external_instance',
  ExternalMobileUnit = 'external_mobile_unit',
  FacilitiesOnly = 'facilities_only',
  Unknown = 'Unknown'
}

export enum RDWInspectionAuthorityAcknowledgementType {
  Light = 'light',
  Heavy = 'heavy',
  Agriculture = 'agriculture',
  Moped = 'moped',
  LPG = 'lpg',
  Investigation = 'investigation',
  ParticleFiltersLight = 'particle_filter_light',
  ParticleFiltersHeavy = 'particle_filter_heavy',
  SpeedLimiter = 'speed_limiter',
  Tachograph = 'tachograph',
  Odometer = 'odometer',
  Unknown = 'unknown'
}

export enum RDWInspectionAuthorityAcknowledgementStatus {
  Active = 'active',
  SuspendedTechnical = 'suspended_technical',
  SuspendedFinancial = 'suspended_financial',
  RevokedByRDW = 'revoked_by_rdw',
  Terminated = 'terminated',
  Unknown = 'unknown'
}

export enum RDWSpecialAddressType {
  Caravan = 'caravan',
  HouseBoat = 'house_boat',
  Across = 'across',
  Near = 'near',
  Unknown = 'unknown'
}

export enum RDWTransactionLogStatus {
  Failed = 'failed',
  Pending = 'pending',
  Success = 'success'
}

export enum ChecklistTaskType {
  Skip = 'bool-skip',
  Advice = 'advice'
}

export enum ForeignChecklistTaskType {
  HaynesPro = 'haynespro_maintenance_task'
}

export enum ChecklistSourceType {
  HaynesPro = 'haynespro'
}

export enum ChecklistStatus {
  Open = 'open',
  Closed = 'closed'
}

export enum ChecklistButtonType {
  Approve = 'approve',
  Reject = 'reject',
  Comment = 'comment',
  Advice = 'advice'
}

export enum ProposalStatus {
  Pending = 'pending',
  Planned = 'planned',
  Declined = 'declined',
  Cancelled = 'cancelled',
  Done = 'done',
  NoShow = 'no_show'
}
export enum AccountDeleteReason {
  Death = 'death',
  NoMoreBusiness = 'no_more_business',
  RequestedByAccount = 'requested_by_account',
  WontReturn = 'wont_return'
}

export enum WorkOrderDeleteReason {
  CustomerNotShowingUp = 'customer_not_showing_up',
  DoubleWorkorder = 'double_workorder',
  Incomplete = 'incomplete',
  NotNeeded = 'not_needed'
}

export enum PriceType {
  Net = 'Net',
  Gross = 'Gross',
  Graduated = 'Graduated',
  NotImplemented = 'NotImplemented'
}

export enum PricingType {
  Fixed = 'fixed',
  Sum = 'sum'
}

export enum TyreAttributeKey {
  TyreFuelConsumption = 'LQK_TYRES_CONSUM_FUEL',
  TyreWetGrip = 'LKQ_TYRES_WETGRIP',
  TyreNoiseClass = 'LKQ_TYRES_NOISE_CLASS',
  TyreNoiseDb = 'LKQ_TYRES_NOISE',
  TyreSnowPerformance = 'LKQ_SNOW_PERF',
  TyreIcePerformance = 'LKQ_ICE_PERF'
}

export enum PriceRuleType {
  MarkUpPercentage = 'mark-up-percentage'
}

export enum PaymentOption {
  Default = 'default',
  CashOnDelivery = 'cash_on_delivery',
  Account = 'account',
  PersonalPurchase = 'personal_purchase'
}

export enum PaymentProvider {
  Mollie = 'mollie'
}

export enum PartAttributeFilterType {
  Empty = '',
  DisplayOnly = 'display_only',
  DetailsViewOnly = 'details_view_only',
  FilterOnly = 'filter_only',
  Checkbox = 'checkbox',
  InlineFilterableText = 'inline_filterable_text',
  Image = 'image',
  DetailInfoImage = 'detail_info_image',
  Hidden = 'hidden',
  NumberRange = 'number_range',
  DateRange = 'date_range'
}

export enum ArchiveType {
  InvoiceArchive = 'invoice_archive',
  PortalArchive = 'portal_archive',
  External = 'external_archive',
  VehicleSalesInvoiceArchive = 'vehicle_sales_invoice_archive',
  Unknown = 'unknown'
}

export enum CmsSystem {
  NA = '',
  Raw = 'raw',
  DrupalJSONAPI = 'drupal_jsonapi',
  WordPress = 'wordpress'
}

export enum CmsContent {
  Banner1 = 'banner_1',
  Banner2 = 'banner_2',
  Banner3 = 'banner_3'
}

export enum TicketSystem {
  NA = '',
  Zendesk = 'zendesk',
  Email = 'email'
}

export enum ProductIDType {
  S1 = 'S1', // S1 numbers, like `111222333`
  SatorGroup = 'SatorGroup', // Hbase art and group numbers, like 's3 000-426'
  SatorBrand = 'SatorBrand', // Hbase art and brand numbers, like 's3 000-008'
  K8 = 'K8', // K8 numbers like '00011122A'
  RAXInternal = 'RAX', // Rijsbergen internal numbers
  RAXCart = 'RAXCart',
  TecDocArtBrand = 'TecdocArtBrand',
  VROOAM = 'VROOAM',
  Brightmotive = 'Brightmotive',
  GrossierNu = 'GrossierNu',
  AldocSupplier = 'AldocSupplier',
  Autotaal = 'Autotaal',
  Deltec = 'Deltec',
  InterSprint = 'InterSprint',
  Isak = 'Isak',
  Stahlgruber = 'Stahlgruber',
  GSI = 'GSI'
}

export enum AppointmentStatus {
  None = 'none',
  Requested = 'requested',
  Confirmed = 'confirmed',
  Canceled = 'canceled',
  Failed = 'failed'
}

export enum ChangeAction {
  Created = 'created',
  Updated = 'updated',
  Converted = 'converted',
  Deleted = 'deleted'
}

export enum ChangeLogEntity {
  Account = 'account',
  Part = 'part',
  Workorder = 'workorder',
  Vehicle = 'vehicle',
  Invoice = 'invoice',
  Credit = 'credit',
  WholesalerOrder = 'wholesaler_order'
}

export enum EnumData {
  Employee = 'employee',
  Support = 'support', // Carsys support
  Account = 'account', // relations
  Cognito = 'cognito',
  API = 'api', // via API requests
  Generated = 'generated', // generated by migrations or other tool. (therefor not exported)
  Worker = 'worker', // as in the worker application that runs on the background.
  External = 'external', // External application
  Telesales = 'telesales' // Telesales users
}

export enum WarningType {
  Error = 'error',
  Warning = 'warning',
  Info = 'info'
}

export enum DisplayType {
  UserSelection = 'user_selection',
  Hidden = 'hidden',
  Info = 'info',
  Image = 'image'
}
export enum RecentlyOpenedEntityType {
  WorkorderInvoice = 'workorder_invoice',
  Workorder = 'workorder',
  Order = 'order'
}

export enum AccountancyGlobalisatie {
  SnelstartNL = 'SnelStart Administration'
}

export enum SageOfflineEnumData {
  DefaultCashAccount = 'CSH001'
}

export enum PaymentRoutingInvoiceType {
  WorkorderCustomer = 'workorder_customer',
  VehicleSale = 'vehicle_sale'
}

export enum OrderType {
  Stock = 'stock',
  Workorder = 'workorder'
}

export enum DiscountType {
  Percentage = 'percentage',
  Amount = 'amount'
}

export enum InputType {
  String = 'string',
  Numeric = 'numeric',
  Boolean = 'bool',
  Integer = 'int',
  Float = 'float',
  Enum = 'enum',
  Ledger = 'ledger'
}

export enum Theme {
  CarSys = 'CarSys',
  CarCat = 'CarCat'
}

export enum SortType {
  Default = 'Default',
  BrandAZ = 'Brand A - Z',
  BrandZA = 'Brand Z - A',
  PriceHighLow = 'Price High - Low',
  PriceLowHigh = 'Price Low - High',
  ProductGroupAZ = 'Product group A - Z',
  ProductGroupZA = 'Product group Z - A',
  BaseIDAZ = 'Part number A - Z',
  BaseIDZA = 'Part number Z - A',
  DeliveryFastSlow = 'Delivery Fast - Slow',
  DeliverySlowFast = 'Delivery Slow - Fast'
}

export enum SchemaDataFormat {
  JSON = 'json'
}

export enum EnumFormFieldType {
  Text = 'Text',
  Hidden = 'Hidden',
  Number = 'Number',
  Select = 'Select',
  Checkbox = 'Checkbox',
  RadioButton = 'RadioButton',
  LicensePlateText = 'LicensePlate'
}
export enum EmissionClass {
  Unknown = 'unknown',
  Euro1 = 'euro_one',
  Euro2 = 'euro_two',
  Euro3 = 'euro_three',
  Euro4 = 'euro_four',
  Euro5 = 'euro_five',
  Euro6 = 'euro_six',
  EuroLessThan5 = 'euro_less_than_five',
  EuroLessThan6 = 'euro_less_than_six'
}

export { default as VehicleIdType } from './vehicle-id-type'

export * from './tyre-axle-position'
export * from './tyre-position'
export * from './tyre-activity-type'
export * from './tyre-season-type'
export * from './tyre-rim-type'
export * from './tyre-profile-measurement-system'
export * from './tyre-status-type'
export * from './recall-criteria-type'
export * from './notification'
export * from './warranty-type'
export * from './mileage-type'
export * from './vehicle-sales-line-template-type'
export * from './rma-status'
export * from './rma-type'
export * from './rma-service'
export * from './rma-return-reason'
export * from './foreign-system-type'
export * from './product-type'
export * from './product-relation'
export * from './product-relation-hierarchy'
export * from './concept-app'
