import React from 'react'
import LoadingButton from './LoadingButton'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Dialog } from './Dialog'
import { useTranslation } from 'react-i18next'

interface ScrollDialogProps {
  title?: string
  content: React.ReactNode
  cancelButtonContent: React.ReactNode
  confirmButtonContent: React.ReactNode
  onClose: () => void
  onConfirm: () => void
  open: boolean
  closeOnClickOutside?: boolean
  closeOnConfirm?: boolean
  isLoading?: boolean
}

export const ConfirmDialog = (props: ScrollDialogProps) => {
  const { t } = useTranslation()

  const handleConfirm = async () => {
    await props.onConfirm()
    if (props.closeOnConfirm) {
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} onClose={props.closeOnClickOutside ? props.onClose : undefined} scroll='paper'>
      <DialogTitle>{props.title ?? t('Form.SubmitConfirmation.Title')}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText component='div'>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={props.onClose}
          disabled={props.isLoading}
          color='primary'
          variant='outlined'
          data-testid='confirm-dialog-cancel-button'
        >
          {props.cancelButtonContent}
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          loading={!!props.isLoading}
          disabled={props.isLoading}
          color='primary'
          variant='contained'
          data-testid='confirm-dialog-confirm-button'
        >
          {props.confirmButtonContent}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
