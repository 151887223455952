import { IFailedRequest } from '../errorHandler'
import defaultHandler from './defaultHandler'

async function replacementVehicleNotFound(failedRequest: IFailedRequest) {
  // If we import this the normal way, a big part of the application will be imported along every page of the gms.
  // This way of importing makes sure this part is only loaded when this specific error is handled.
  // This decreses the bundle size significantly, which even improve load performance.
  const { isMounted } = await import(/* webpackPrefetch: true */ 'app/vehicles/components/VehicleDetails')

  if (isMounted) {
    if (failedRequest?.error?.response?.data?.Error === 'ErrReplacementVehicleNotFound') {
      // do nothing
      return
    }
    // do default
    defaultHandler(failedRequest?.error?.response?.data)
  }
}

export default replacementVehicleNotFound
