import { AnyAction, combineReducers } from 'redux'
import propOr from 'lodash/fp/propOr'
import { createReducer } from '@cbgms/base/utils/redux'
import * as types from './types'
import { EmptyDate } from '@cbgms/base/utils/datetime'
import repairTimes from 'app/workorder/state/workorder/repairtimes'
import { WorkorderTotalsState } from 'app/workorder/state/workorder/reducers/WorkorderTotalsState'
import totals from './reducers/totals'
import serviceSchedule from 'app/workorder/state/workorder/serviceSchedule'
import { EmptyUUID } from '@cbgms/base/utils/uuid'
import { OrderSystem, WorkorderAppointmentStatus } from '@cbgms/api/modules/enums'
import { IAccount, IApkWithStatus, IWorkorder, IWorkorderAppointment, IWorkorderSundries, RecentlyModifiedWorkorderLine } from '@cbgms/api'
import { IOrderAccountData } from '@cbgms/api/modules/wholesaler'
import { workorderDetailsReducer } from './reducers/workorderDetailsReducer'
import { workorderAccountReducer } from './reducers/workorderAccountReducer'
import { workorderInvoiceAccountReducer } from './reducers/workorderInvoiceAccountReducer'
import { workorderChecklistsReducer } from './reducers/workorderChecklistsReducer'
import { workorderSupplierOrderPartsReducer } from './reducers/workorderSupplierOrderPartsReducer'
import { workorderLinesReducer } from './reducers/workorderLinesReducer'
import { workorderFirstAppointmentReducer } from './reducers/workorderFirstAppointmentReducer'
import { workorderInvoiceReducer } from './reducers/workorderInvoiceReducer'
import { workorderSupplierOrderParamsReducer } from './reducers/workorderSupplierOrderParamsReducer'
import { WorkorderLineState } from './selectors'
import { GET_ORDER_WITH_RELATED_SUCCESS } from 'app/order/state/types'

export interface IWorkorderReducerState {
  account: Readonly<IAccount>
  apk: Readonly<IApkWithStatus>
  appointment: Readonly<IWorkorderAppointment>
  invoiceAccount: Readonly<IAccount>
  checklists: ReturnType<typeof workorderChecklistsReducer>
  deliveryTimes: ReturnType<typeof deliveryTimes>
  pickupTimes: ReturnType<typeof pickupTimes>
  supplierContactInfo: ReturnType<typeof supplierContactInfo>
  supplierOrderParts: ReturnType<typeof workorderSupplierOrderPartsReducer>
  supplierOrderParams: ReturnType<typeof workorderSupplierOrderParamsReducer>
  customerContactInfo: ReturnType<typeof customerContactInfo>
  invoice: ReturnType<typeof workorderInvoiceReducer>
  serviceSchedule: ReturnType<typeof serviceSchedule>
  orderAccounts: ReturnType<typeof orderAccounts>
  repairTimes: ReturnType<typeof repairTimes>
  details: IWorkorder
  line: WorkorderLineState
  lines: ReturnType<typeof workorderLinesReducer>
  totals: WorkorderTotalsState
  firstAppointment: ReturnType<typeof workorderFirstAppointmentReducer>
  recentlyModifiedWorkorderLines: IRecentlyModifiedLinesState
  sundries: IWorkorderSundries | null
}

export interface IRecentlyModifiedLinesState {
  lines: RecentlyModifiedWorkorderLine[]
}

export interface IOrderAccount {
  CreatedAt: string
  ShopID: string
  UUID: string
  Username: string
  WholesalerUUID: string
}

export interface IWholesaler {
  CreatedAt: string
  LogoURL: string
  Name: string
  OrderSystem: OrderSystem
  UUID: string
}

const getRoutes = propOr([], 'data.Data.Routes')

const line = (state: any = {}, action: AnyAction) => {
  switch (action.type) {
    case types.GET_WORKORDER_LINE_SUCCESS:
      return action.payload.data?.Data || {}
    default:
      return state
  }
}

const deliveryTimes = createReducer([], {
  [types.GET_ORDER_DELIVERY_TIMES_SUCCESS]: (_, { payload }) => {
    return getRoutes(payload)
  }
})

const pickupTimes = createReducer([], {
  [types.GET_ORDER_PICKUP_TIMES_SUCCESS]: (_, { payload }) => {
    return getRoutes(payload)
  }
})

const sundries = createReducer(null, {
  [types.GET_WORKORDER_LINES_SUCCESS]: (_, { payload }) => {
    const sundries = payload.data.Data.Sundries

    if (!sundries || sundries.length === 0) {
      return null
    }

    return sundries[0]
  }
})

const defaultAddressInfo = {
  Address: '',
  City: '',
  Name: '',
  PostalCode: ''
}

const supplierContactInfo = createReducer(defaultAddressInfo, {
  [types.GET_SUPPLIER_CONTACT_INFO_SUCCESS]: (_, { payload }) => {
    return propOr(defaultAddressInfo, 'data.Data', payload)
  }
})

const customerContactInfo = createReducer(defaultAddressInfo, {
  [types.GET_CUSTOMER_CONTACT_INFO_SUCCESS]: (_, { payload }) => {
    return propOr(defaultAddressInfo, 'data.Data', payload)
  }
})

const defaultOrderAccountState: IOrderAccountData[] = []
const orderAccounts = createReducer(defaultOrderAccountState, {
  [types.GET_ORDER_ACCOUNTS_SUCCESS]: (_, { payload }) => {
    return propOr([], 'data.Data', payload)
  },
  [types.RESET_ORDER_ACCOUNTS]: () => []
})

const recentlyModifiedWorkorderLines = createReducer(
  { lines: [] },
  {
    [types.LOAD_CHANGED_LINES_SUCCESS]: (_, { payload }) => {
      return { lines: payload.data.Data }
    }
  }
)

const apk = createReducer(null, {
  [types.GET_WORKORDER_WITH_RELATED_SUCCESS]: (_, { payload }) => {
    const data = payload && payload.data && payload.data.Data

    return data.APKWithStatus ? data.APKWithStatus : null
  }
})

const defaultWorkorderAppointmentState: IWorkorderAppointment = {
  WorkorderUUID: EmptyUUID,
  WorkstationName: '',
  StartingTime: '',
  Date: EmptyDate,
  EmployeeUUID: EmptyUUID,
  Status: WorkorderAppointmentStatus.DueIn
}

const appointment = createReducer(defaultWorkorderAppointmentState, {
  [types.GET_WORKORDER_WITH_RELATED_SUCCESS]: (_, { payload }) => {
    const data = payload && payload.data && payload.data.Data

    return data.Appointment ? data.Appointment : null
  },
  [GET_ORDER_WITH_RELATED_SUCCESS]: (_, { payload }) => {
    const data = payload && payload.data && payload.data.Data

    return data.Appointment ? data.Appointment : null
  }
})

// @ts-ignore
export default combineReducers<IWorkorderReducerState>({
  account: workorderAccountReducer,
  apk,
  appointment,
  invoiceAccount: workorderInvoiceAccountReducer,
  checklists: workorderChecklistsReducer,
  deliveryTimes,
  pickupTimes,
  supplierContactInfo,
  supplierOrderParts: workorderSupplierOrderPartsReducer,
  supplierOrderParams: workorderSupplierOrderParamsReducer,
  customerContactInfo,
  invoice: workorderInvoiceReducer,
  serviceSchedule,
  orderAccounts,
  repairTimes,
  details: workorderDetailsReducer,
  line,
  lines: workorderLinesReducer,
  totals,
  firstAppointment: workorderFirstAppointmentReducer,
  recentlyModifiedWorkorderLines,
  sundries
})
